import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiCheckToken } from '../services/firebase/database';

import { applyColors } from '../styles/applyColors';
import '../styles/AddToken.css';
applyColors();

const AddToken = ({ user }) => {
  const [selectedTokens, setSelectedTokens] = useState(null);
  const [currentTokens, setCurrentTokens] = useState(0);
  const [containerHeight, setContainerHeight] = useState('auto');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchToken = async () => {
      if (user) {
        try {
          const userToken = await apiCheckToken(user.uid);
          setCurrentTokens(userToken);
        } catch (error) {
          console.error("Failed to fetch token:", error);
        }
      }
    };
    fetchToken();

    const updateHeight = () => {
      const headerHeight = document.querySelector('.header')?.offsetHeight || 0;
      const footerHeight = document.querySelector('.footer')?.offsetHeight || 0;
      const windowHeight = window.innerHeight;
      const newHeight = windowHeight - headerHeight - footerHeight;
      setContainerHeight(`${newHeight}px`);
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => window.removeEventListener('resize', updateHeight);
  }, [user]);

  const options = [
    { tokens: 20, price: 1000, bonus: 0 },
    { tokens: 100, price: 5000, bonus: 0 },
    { tokens: 210, price: 10000, bonus: 10 },
    { tokens: 430, price: 20000, bonus: 30 },
    { tokens: 1200, price: 50000, bonus: 200 },
    { tokens: 2500, price: 100000, bonus: 500 }
  ];

  const handleOptionClick = (option) => {
    setSelectedTokens(option.tokens);
  };

  const handlePayment = () => {
    if (selectedTokens) {
      const selectedOption = options.find(option => option.tokens === selectedTokens);
      navigate('/checkout', { state: { selectedOption } });
    }
  };

  return (
    <div className="main-container" style={{ minHeight: containerHeight }}>
      <div className="add-token-container">
        <div className="current-tokens-container">
          <div className="current-tokens-text">
            <span>현재 보유 토큰</span>
            <span className="current-token-amount">{currentTokens.toLocaleString()}</span>
          </div>
        </div>
        <div className="options">
          {options.map((option, index) => (
            <div
              key={index}
              className={`option ${selectedTokens === option.tokens ? 'selected' : ''}`}
              onClick={() => handleOptionClick(option)}
            >
              <div className="token-amount">{option.tokens} 토큰</div>
              <div className="price">{option.price.toLocaleString()}원</div>
              {option.bonus > 0 && (
                <div className="bonus">+{option.bonus} 보너스</div>
              )}
            </div>
          ))}
        </div>
        <button 
          className="pay-button" 
          disabled={!selectedTokens}
          onClick={handlePayment}
        >
          결제하기
        </button>
      </div>
    </div>
  );
};

export default AddToken;