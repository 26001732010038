// 데이터베이스 관련 함수들 (데이터 읽기, 쓰기 등)
import { ref, set, get, update } from "firebase/database";
import { db } from "./index";
import { getCurrentTimeFormatted, logAction} from "./usedFunction"

export const apiSignUp = async (user) => {
  try {
    const userRef = ref(db, `user/${user.uid}`);
    const snapshot = await get(userRef);
    
    if (snapshot.exists()) {
      await logAction("login", user.uid, { email: user.email, name: user.displayName }); // 로깅
      return "LogIn";
    }
    
    await set(userRef, {
      uid: user.uid,
      email: user.email,
      name: user.displayName,
      token: 200,
      archive: {}
    });

    await logAction("signup", user.uid, { email: user.email, name: user.displayName }); // 로깅
    
    return "SignUp";
  } catch (error) {
    console.error("Error in apiSignUp:", error);
    throw error;
  }
};

export const apiLogOut = async (user) => {
  try {
    await logAction("logout", user.uid, { email: user.email, name: user.displayName }); // 로깅

    return "LogOut";
  } catch (error) {
    console.error("Error in apiLogOut:", error);
    throw error;
  }
}

export const apiCheckUser = async (uid) => {
  try {
    const userRef = ref(db, `user/${uid}`);
    const snapshot = await get(userRef);
    
    if (snapshot.exists()) {
      return snapshot.val()
    }
    else {
      throw new Error("This UID doesn't exists.");
    }
  } catch (error) {
    console.error("Error in apiCheckUser:", error);
    throw error;
  }
};

export const apiCheckUserArchive = async (uid) => {
  try {
    const userRef = ref(db, `user/${uid}`);
    const snapshot = await get(userRef);
    
    if (snapshot.exists()) {
      const archive = snapshot.val().archive;
      
      const sortedArchive = Object.keys(archive)
        .sort((a, b) => b.localeCompare(a))
        .reduce((result, key) => {
          result[key] = archive[key];
          return result;
        }, {});
      
      return sortedArchive;
    }
    else {
      throw new Error("This UID doesn't exists.");
    }
  } catch (error) {
    console.error("Error in apiCheckUserArchive:", error);
    throw error;
  }
};

export const apiCheckToken = async (uid) => {
  try {
    const userRef = ref(db, `user/${uid}`);
    const snapshot = await get(userRef);
    
    if (snapshot.exists()) {
      return snapshot.val().token
    }
    else {
      throw new Error("This UID doesn't exists.");
    }
  } catch (error) {
    console.error("Error in apiCheckToken:", error);
    throw error;
  }
};

export const apiCheckVideo = async (title) => {
  try {
    const userRef = ref(db, `admin/archive/${title.slice(0, 14)}`);
    const snapshot = await get(userRef);
    
    if (snapshot.exists()) {
      return snapshot.val()
    }
    else {
      throw new Error("This Video doesn't exists.");
    }
  } catch (error) {
    console.error("Error in apiCheckVideo:", error);
    throw error;
  }
};

export const apiAddToken = async (uid, add_token) => {
  try {
    const userRef = ref(db, `user/${uid}`);
    const snapshot = await get(userRef);
    
    if (snapshot.exists()) {
      const userInfo = snapshot.val();
      if ('token' in userInfo) {
        const currentToken = parseInt(userInfo.token, 10);
        const addTokenInt = parseInt(add_token, 10);

        const newToken = currentToken + addTokenInt;
        
        await update(userRef, { token: newToken });

        await logAction("add_token", uid, { added_token: addTokenInt }, { token: currentToken }, { token: newToken }); //로깅
        
        return { message: "Token updated successfully", newToken };
      } else {
        throw new Error("Token field does not exist for this user.");
      }
    } else {
      throw new Error("This UID doesn't exist.");
    }
  } catch (error) {
    console.error("Error in apiAddToken:", error);
    throw error;
  }
};

export const apiSubToken = async (uid, sub_token) => {
  try {
    const userRef = ref(db, `user/${uid}`);
    const snapshot = await get(userRef);
    
    if (snapshot.exists()) {
      const userInfo = snapshot.val();
      if ('token' in userInfo) {
        const currentToken = parseInt(userInfo.token, 10);
        const subTokenInt = parseInt(sub_token, 10);
        
        if (currentToken >= subTokenInt){
          const newToken = currentToken - subTokenInt;
          await update(userRef, { token: newToken });

          await logAction("sub_token", uid, { added_token: subTokenInt }, { token: currentToken }, { token: newToken }); //로깅

          return { message: "Token updated successfully", newToken };
        }
        
        else {
          throw new Error("Token isn't enough");
        }
      } else {
        throw new Error("Token field does not exist for this user.");
      }
    } else {
      throw new Error("This UID doesn't exist.");
    }
  } catch (error) {
    console.error("Error in apiAddToken:", error);
    throw error;
  }
};

export const apiUploadVideo = async (uid, title, message, used_token) => {
  try {
    const newVideoInfo = {
      video_upload_time: getCurrentTimeFormatted(),
      type:"video",
      uid,
      title,
      message,
      used_token: parseInt(used_token, 10),
      is_srt: false,
      srt_title: "none",
      srt_upload_time: "none",
      url:"none"
    };
    
    const userRef = ref(db, `user/${uid}`);
    const adminRef = ref(db, `admin`);

    const [userSnapshot, adminSnapshot] = await Promise.all([
      get(userRef),
      get(adminRef)
    ]);

    if (!userSnapshot.exists()) {
      throw new Error("This UID doesn't exist.");
    }

    const adminInfo = adminSnapshot.val();
    const userInfo = userSnapshot.val();

    const updatedAdminArchive = {
      ...adminInfo.archive,
      [title.slice(0, 14)]: newVideoInfo
    };

    const updatedUserArchive = {
      ...userInfo.archive,
      [title.slice(0, 14)]: newVideoInfo
    };

    await Promise.all([
      update(adminRef, { archive: updatedAdminArchive }),
      update(userRef, { archive: updatedUserArchive })
    ]);

    await logAction("upload_video", uid, { title }, null, newVideoInfo); //로깅

    return { message: "Video uploaded successfully", updatedArchive: updatedUserArchive };
  } catch (error) {
    console.error("Error in apiUploadVideo:", error);
    throw error;
  }
};

export const apiUploadYoutube = async (uid, title, message, used_token, url) => {
  try {
    const newVideoInfo = {
      video_upload_time: getCurrentTimeFormatted(),
      type:"youtube",
      uid,
      title,
      message,
      used_token: parseInt(used_token, 10),
      is_srt: false,
      srt_title: "none",
      srt_upload_time: "none",
      url
    };
    
    const userRef = ref(db, `user/${uid}`);
    const adminRef = ref(db, `admin`);

    const [userSnapshot, adminSnapshot] = await Promise.all([
      get(userRef),
      get(adminRef)
    ]);

    if (!userSnapshot.exists()) {
      throw new Error("This UID doesn't exist.");
    }

    const adminInfo = adminSnapshot.val();
    const userInfo = userSnapshot.val();

    const updatedAdminArchive = {
      ...adminInfo.archive,
      [title.slice(0, 14)]: newVideoInfo
    };

    const updatedUserArchive = {
      ...userInfo.archive,
      [title.slice(0, 14)]: newVideoInfo
    };

    await Promise.all([
      update(adminRef, { archive: updatedAdminArchive }),
      update(userRef, { archive: updatedUserArchive })
    ]);

    await logAction("upload_video", uid, { title }, null, newVideoInfo); //로깅

    return { message: "Video uploaded successfully", updatedArchive: updatedUserArchive };
  } catch (error) {
    console.error("Error in apiUploadVideo:", error);
    throw error;
  }
};

export const apiUploadSRT = async (uid, title, srt_title) => {
  try {
    const currentTime = getCurrentTimeFormatted();
    const updates = {
      is_srt: true,
      srt_title: srt_title,
      srt_upload_time: currentTime
    };

    // admin 데이터 업데이트
    const adminRef = ref(db, `admin/archive/${title.slice(0, 14)}`);
    const adminSnapshot = await get(adminRef);
    
    if (adminSnapshot.exists()) {
      await update(adminRef, updates);
    } else {
      console.warn(`Admin archive for title "${title}" does not exist.`);
    }

    // user 데이터 업데이트
    const userRef = ref(db, `user/${uid}/archive/${title.slice(0, 14)}`);
    const userSnapshot = await get(userRef);
    
    if (userSnapshot.exists()) {
      await update(userRef, updates);
    } else {
      console.warn(`User archive for UID "${uid}" and title "${title}" does not exist.`);
    }

    await logAction("upload_srt", uid, { title }, null, updates); //로깅

    return { message: "SRT uploaded successfully", title, srt_title };

  } catch (error) {
    console.error("Error in apiUploadSRT:", error);
    throw error;
  }
};