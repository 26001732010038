// 스토리지 관련 함수들
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from "./index";
import { getCurrentTimeFormatted, logAction } from "./usedFunction"
import { apiCheckVideo } from "./database"

export const uploadFileToFirebase = (file, onProgress, onError, onComplete) => {
  const newFileName = `${getCurrentTimeFormatted()}_${file.name}`;
  const storageRef = ref(storage, 'videos/' + newFileName);
  const uploadTask = uploadBytesResumable(storageRef, file);

  uploadTask.on('state_changed',
    (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      onProgress(progress);
    },
    (error) => {
      onError(error);
    },
    async () => {
      onComplete(newFileName);
    }
  );
};

export const downloadFileFromFirebase = async (srtFileTitle) => {
  try {
    const srtRef = ref(storage, `/srts/${srtFileTitle}.srt`);
    const downloadURL = await getDownloadURL(srtRef);
    
    const response = await fetch(downloadURL);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;

    // 파일명에서 날짜/시간 부분을 제거하고 실제 파일명만 추출
    const newFileName = srtFileTitle.split('_').slice(1).join('_');
    const finalFileName = newFileName.endsWith('.srt') ? newFileName : `${newFileName}.srt`;
    a.download = finalFileName;

    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);

    const userUid = await apiCheckVideo(srtFileTitle)

    logAction(
      "down_srt",
      userUid.uid,
      {srt : srtFileTitle}
    )

    return 'completed';
  } catch (error) {
    console.error("Error downloading file:", error);
    throw error;
  }
};