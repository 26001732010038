import React from 'react';
import '../styles/Privacy.css';

const Privacy = () => {
  return (
    <div className="privacy-container">
      <h1>개인정보 처리 방침 (24년 9월 19일 개정)</h1>
      <p>(주)에이젠다 "WithG" 개인정보 처리 방침 </p>

      <section>
        <h2>제1조 (개인정보의 수집 및 이용에 관한 안내)</h2>
        <p>"윗지 (WithG)" 이용을 위해 개인정보의 수집이 필요합니다.</p>
      </section>

      <section>
        <h2>제2조 (가명정보 처리에 관한 사항)</h2>
        <p>회사는 모델 성능 및 품질 개선 목적으로 다음과 같이 사용자의 동의를 받은 가명정보를 처리하고 있습니다. 가명정보란 개인정보를 가명처리함으로써 원래의 상태로 복원하기 위한 추가 정보의 사용 · 결합 없이는 특정 개인을 알아볼 수 없는 정보입니다.</p>
      </section>

      <section>
        <h2>제3조 (개인정보자동수집 장치의 설치와 운영거부에 관한 사항)</h2>
        <p>회사는 서비스 이용과정에서 이용자로부터 다음과 같은 정보들이 자동으로 생성/수집되고 다음의 목적으로 이용될 수 있습니다.</p>
      </section>

      <section>
        <h2>제4조 (개인정보 처리 위탁)</h2>
        <p>회사는 원활한 개인정보 업무처리를 위하여 일부 개인정보처리업무를 위탁하고 있으며 위탁 계약 체결 시 관련법령에 따라 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다. 위탁업무의 내용이나 수탁자가 추가, 변경될 경우에는 지체 없이 관련 법령에 따른 사전 동의 안내 또는 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.</p>
      </section>

      <section>
        <h2>제5조 (개인정보의 제3자 제공)</h2>
        <p>회사는 정보주체의 개인정보를 ‘제1조 개인정보의 수집 및 이용에 관한 안내’에서 명시한 범위 내에서만 처리하며, 이용자의 별도 사전동의, 관련법령의 특별한 요구가 발생하는 경우에만 개인정보를 제3자에게 제공합니다.</p>
      </section>

      <section>
        <h2>제6조 (개인정보의 국외이전)</h2>
        <p>회사는 개인정보를 국외의 다른 사업자에게 제공하지 않습니다. 다만, 정보통신서비스의 제공에 관한 계약 이행 및 이용자 편의 증진 등을 위하여 다음과 같이 개인정보 처리 업무를 국외에 전송하고 있습니다.</p>
      </section>

      <section>
        <h2>제7조 (이용자 및 법정대리인의 권리와 그 행사방법)</h2>
        <p>이용자 및 만 14세 미만의 사용자의 경우 법정 대리인은 개인정보의 조회, 수정 및 계정 삭제가 가능합니다.</p>
      </section>

      <section>
        <h2>제8조 (개인정보 관리책임자)</h2>
        <p>회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 이용자의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보보호책임자를 지정하고 있습니다.</p>
        <p><br/>이름:안효인</p>
        <p>소속:주식회사에이젠다</p>
        <p>직위:대표이사</p>
        <p>연락처:info@aigenda.me</p>
      </section>

      <section>
        <h2>제9조 (개인정보처리방침 변경에 관한 사항)</h2>
        <ol>
          <li>개인정보취급방침은 더욱 편리한 서비스 제공을 위해 변경 가능합니다.</li>
          <li>중요한 변경사항이 있을 때에는 알기 쉬운 방법으로 개정 전에 이용자에게 개정 내용을 공지합니다.</li>
        </ol>
      </section>
    </div>
  );
};

export default Privacy;