import React, { useState, useEffect } from 'react';
import FileUploader from './FileUploader';
import YoutubeUploader from './YoutubeUploader';

import { applyColors } from '../styles/applyColors';
import '../styles/MainContainer.css';
applyColors();

function MainContainer({ user }) {
  const [activeUploader, setActiveUploader] = useState('youtube');
  const [containerHeight, setContainerHeight] = useState('auto');

  useEffect(() => {
    const updateHeight = () => {
      const headerHeight = document.querySelector('.header').offsetHeight;
      const footerHeight = document.querySelector('.footer').offsetHeight;
      const windowHeight = window.innerHeight;
      const newHeight = windowHeight - headerHeight - footerHeight;
      setContainerHeight(`${newHeight}px`);
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  return (
    <div className='main-container' style={{ height: containerHeight }}>
      <div className="content-wrapper">
        <div className='main-info'>
          <div className='main-desc'>
            쉽고 빠르게 번역 자막을 확인하세요
          </div>
        </div>
        <div className="uploader-selector">
          <button 
            className={`selector-button ${activeUploader === 'youtube' ? 'active' : ''}`}
            onClick={() => setActiveUploader('youtube')}
          >
            Youtube URL
          </button>
          <button 
            className={`selector-button ${activeUploader === 'file' ? 'active' : ''}`}
            onClick={() => setActiveUploader('file')}
          >
            Video File
          </button>
        </div>
        {activeUploader === 'youtube' ? (
          <YoutubeUploader user={user} />
        ) : (
          <FileUploader user={user} />
        )}
      </div>
    </div>
  );
}

export default MainContainer;