import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { auth } from "./services/firebase";
import { onAuthStateChanged } from "firebase/auth";
import Header from "./components/Header";
import MainContainer from './components/MainContainer';
import UserInfo from './components/UserInfo';
import Footer from './components/Footer';
import NonLogin from './components/NonLogin';
import Terms from './components/Terms';
import Privacy from './components/Privacy';

import { applyColors } from './styles/applyColors';
import './styles/App.css';
import AddToken from './components/AddToken';
import { Checkout } from './components/Checkout';

applyColors();

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="App">
      <Router>
        <Header user={user} setUser={setUser} />
        <Routes>
          <Route path="/" element={
            <>
              {user ? <MainContainer user={user} /> : <NonLogin/>}
              <Footer/>
            </>
          }/>
          <Route path="/user" element={
            <>
              {user ? <UserInfo uid={user.uid} /> : <NonLogin/>}
              <Footer/>
            </>
          }/>
           <Route path="/addtoken" element={
            <>
              {user ? <AddToken user={user} /> : <NonLogin/>}
              <Footer/>
            </>
          }/>
          <Route path="/checkout" element={
            <>
              {user ? <Checkout /> : <NonLogin/>}
            </>
          }/>
          <Route path="/terms" element={<Terms />}/>
          <Route path="/privacy" element={<Privacy />}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
