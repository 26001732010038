import React, { useState, useEffect } from 'react';
import { apiUploadYoutube } from '../services/firebase/database';
import { getCurrentTimeFormatted } from "../services/firebase/usedFunction"
import ConfirmUploadPopup from './ConfirmUploadPopup';
import axios from 'axios';

import { applyColors } from '../styles/applyColors';
import '../styles/YoutubeUploader.css';
applyColors();

const youtubeKey = process.env.REACT_APP_YOUTUBE_API_KEY

function YoutubeUploader({ user }) {
  const [url, setUrl] = useState('');
  const [videoId, setVideoId] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [fileTitle, setFileTitle] = useState('');
  const [videoDuration, setVideoDuration] = useState('');
  const [useToken, setUseToken] = useState(0);

  useEffect(() => {
    const extractVideoId = (url) => {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      const match = url.match(regExp);
      return (match && match[2].length === 11) ? match[2] : null;
    };

    if (url) {
      const id = extractVideoId(url);
      setVideoId(id);
    }
  }, [url]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (videoId) {
      try {
        const response = await axios.get(`https://www.googleapis.com/youtube/v3/videos`, {
          params: {
            part: 'snippet,contentDetails',
            id: videoId,
            key: youtubeKey
          }
        });

        const videoData = response.data.items[0];
        
        if (videoData) {
          const duration = videoData.contentDetails.duration;
          const title = videoData.snippet.title;

          const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
          const hours = parseInt((match[1] && match[1].replace('H', '')) || 0);
          const minutes = parseInt((match[2] && match[2].replace('M', '')) || 0);
          const seconds = parseInt((match[3] && match[3].replace('S', '')) || 0);
          
          const totalSeconds = hours * 3600 + minutes * 60 + seconds;

          setVideoDuration(totalSeconds);
          setUseToken(Math.floor(totalSeconds / 6))
          setFileTitle(title);
        
          setShowPopup(true)
        } else {
          console.error('No video data found');
        }
      } catch (error) {
        console.error('Error fetching video data:', error);
      }
    }
  };

  const getOperationYoutubeTranslator = (uid, title, url, usedtoken) => {
    axios.get(`https://0wbl0jewsu2y.share.zrok.io/operationyoutubetranslator?uid=${uid}&url=${url}&title=${title}&usedtoken=${usedtoken}`)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    axios.get(`https://shdobsuit0kt.share.zrok.io/send_update_video_email?uid=${uid}&title=${title}`)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  const handleConfirmUpload = () => {
    setShowPopup(false);
    uploadYoutube();
  };

  const handleCancelUpload = () => {
    setShowPopup(false);
  };

  const uploadYoutube = () => {
    const fileName = `${getCurrentTimeFormatted()}_${fileTitle}`;
    const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, "");
    apiUploadYoutube(user.uid, fileNameWithoutExtension, "test", useToken, url)
    getOperationYoutubeTranslator(user.uid, fileNameWithoutExtension, url, useToken)
    alert("전송 완료")
  };

  return (
    <>
      <div className="youtube-uploader">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="업로드할 Youtube URL 붙여넣기"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
          <button type="submit">
            <span className="arrow-icon">➜</span>
          </button>
        </form>
        {videoId && (
          <div className="video-preview">
            <iframe
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/${videoId}`}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            ></iframe>
          </div>
        )}
      </div>
      {showPopup && (
        <ConfirmUploadPopup
          user={user}
          fileName={fileTitle}
          videoDuration={videoDuration}
          useToken={useToken}
          onConfirm={handleConfirmUpload}
          onCancel={handleCancelUpload}
          url={url}
        />
      )}
    </>
    
  );
}

export default YoutubeUploader;