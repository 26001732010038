import React, { useState, useEffect, useRef } from 'react';
import { apiCheckUserArchive } from '../services/firebase/database';
import { downloadFileFromFirebase } from '../services/firebase/storage';

import { applyColors } from '../styles/applyColors';
import styles from '../styles/UserInfo.module.css';
applyColors()

function UserInfo({storage, uid}) {
  const [archiveData, setArchiveData] = useState({});
  const [downloadStatus, setDownloadStatus] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [showFullTitle, setShowFullTitle] = useState(null);
  const itemsPerPage = 5;
  const containerRef = useRef(null);

  useEffect(() => {
    const updateHeight = () => {
      if (containerRef.current) {
        const headerHeight = document.querySelector('.header').offsetHeight;
        const footerHeight = document.querySelector('.footer').offsetHeight;
        const windowHeight = window.innerHeight;
        const newHeight = windowHeight - headerHeight - footerHeight;
        containerRef.current.style.height = `${newHeight}px`;
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  const fetchUserArchive = async () => {
    try {
      const response = await apiCheckUserArchive(uid);
      setArchiveData(response);
    } catch (error) {
      console.error("Error fetching user archive:", error);
    }
  }

  const formatDateTime = (dateTimeString) => {
    if (dateTimeString.length !== 14) return dateTimeString;
    
    const year = dateTimeString.slice(0, 4);
    const month = dateTimeString.slice(4, 6);
    const day = dateTimeString.slice(6, 8);
    const hour = dateTimeString.slice(8, 10);
    const minute = dateTimeString.slice(10, 12);
    const second = dateTimeString.slice(12, 14);
    
    return (
      <div>
        <div>{`${year}.${month}.${day}`}</div>
        <div>{`${hour}:${minute}:${second}`}</div>
      </div>
    );
  }

  useEffect(() => {
    fetchUserArchive();
  }, [fetchUserArchive]);

  const handleDownload = async (srtFileTitle) => {
    setDownloadStatus(prev => ({ ...prev, [srtFileTitle]: 'downloading' }));
    try {
      await downloadFileFromFirebase(srtFileTitle);
      setDownloadStatus(prev => ({ ...prev, [srtFileTitle]: 'completed' }));
    } catch (error) {
      console.error("Error downloading file:", error);
      setDownloadStatus(prev => ({ ...prev, [srtFileTitle]: 'error' }));
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Object.entries(archiveData).slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(Object.keys(archiveData).length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const truncateTitle = (title, maxLength) => {
    if (title.length <= maxLength) return title;
    return title.slice(0, maxLength - 4);
  };

  return (
    <div className={styles['user-info-container']} ref={containerRef}>
      {Object.keys(archiveData).length > 0 ? (
        <>
          <div className={styles['table-container']}>
            <table className={styles['archive-table']}>
              <thead>
                <tr>
                  <th>업로드 시간</th>
                  <th>비디오 제목</th>
                  <th>토큰</th>
                  <th>다운로드</th>
                </tr>
              </thead>
              <tbody>
              {currentItems.map(([key, item]) => (
                <tr key={key}>
                  <td>{formatDateTime(item.video_upload_time)}</td>
                  <td className={styles['video-title']}>
                    {item.type === "youtube" ? (
                      <a href={item.url} target="_blank" rel="noopener noreferrer">
                        {truncateTitle(item.title, 25).slice(15)}
                      </a>
                    ) : (
                      truncateTitle(item.title, 25).slice(15)
                    )}
                    {item.title.length > 25 && (
                      <button className={styles['show-full-title']} onClick={() => setShowFullTitle(item.title)}>
                        ...
                      </button>
                    )}
                  </td>
                  <td>{item.used_token}</td>
                  <td>
                    {item.is_srt ? (
                      <button 
                        className={styles['download-button']} 
                        onClick={() => handleDownload(item.srt_title)}
                        disabled={downloadStatus[item.srt_title] === 'downloading'}
                      >
                        {downloadStatus[item.srt_title] === 'downloading' ? '다운중...' : '다운로드'}
                      </button>
                    ) : (
                      <span className={styles.generating}>자막 생성중...</span>
                    )}
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
          <div className={styles.pagination}>
            {pageNumbers.map(number => (
              <button key={number} onClick={() => setCurrentPage(number)} className={currentPage === number ? styles.active : ''}>
                {number}
              </button>
            ))}
          </div>
        </>
      ) : (
        <p>데이터를 불러오는 중...</p>
      )}
      {showFullTitle && (
        <div className={styles['popup-overlay']} onClick={() => setShowFullTitle(null)}>
          <div className={styles['popup-content']} onClick={(e) => e.stopPropagation()}>
            <h3>전체 제목</h3>
            <p>{showFullTitle.slice(15)}</p>
            <button onClick={() => setShowFullTitle(null)}>닫기</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserInfo;