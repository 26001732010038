import { ref, set} from "firebase/database";
import { db } from "./index";

// 시간 포맷팅 함수
export const getCurrentTimeFormatted = () => {
  const now = new Date();
  return now.getFullYear().toString() +
         (now.getMonth() + 1).toString().padStart(2, '0') +
         now.getDate().toString().padStart(2, '0') +
         now.getHours().toString().padStart(2, '0') +
         now.getMinutes().toString().padStart(2, '0') +
         now.getSeconds().toString().padStart(2, '0');
};

// 로깅 함수
export const logAction = async (action, uid, details, before = null, after = null) => {
  const timestamp = Date.now();
  const logRef = ref(db, `logs/${timestamp}`);
  await set(logRef, {
    action,
    uid,
    details,
    before,
    after,
    timestamp: new Date().toISOString()
  });
};