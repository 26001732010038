import React from 'react';
import '../styles/Terms.css';

const Terms = () => {
  return (
    <div className="terms-container">
      <h1>서비스 이용약관 (24년 9월 19일 개정)</h1>
      <p>(주)에이젠다 "WithG" 서비스 이용약관 </p>

      <section>
        <h2>제1조 (목적)</h2>
        <p>본 약관은 주식회사 에이젠다(이하 "회사"라 한다)가 제공하는 "WithG-번역" 서비스(이하 "서비스"라 한다)의 이용에 관한 조건과 절차, 회사와 이용자의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.</p>
      </section>

      <section>
        <h2>제2조 (정의)</h2>
        <ol>
          <li>"서비스"란 사용자가 올린 영상 또는 유튜브 링크의 영어 음성을 한국어 자막으로 변환하는 웹 서비스를 의미합니다.</li>
          <li>"이용자"란 본 서비스에 접속하여 이 약관에 따라 서비스를 이용하는 회원 및 비회원을 의미합니다.</li>
          <li>"회원"이란 회사에 회원가입을 한 자로서, 서비스를 지속적으로 이용할 수 있는 자를 의미합니다.</li>
          <li>"비회원"이란 회원에 가입하지 않고 서비스를 이용하는 자를 의미합니다.</li>
          <li>"토큰"이란 이용자가 서비스 이용을 위해 선불 결제한 가상의 포인트로, 영상의 길이에 따라 차감되는 결제 수단을 의미합니다.</li>
        </ol>
      </section>

      <section>
        <h2>제3조 (약관의 명시와 개정)</h2>
        <ol>
          <li>회사는 이 약관의 내용과 상호, 영업소 소재지, 연락처, 사업자등록번호 등을 서비스 초기화면에 게시하여 이용자가 쉽게 알 수 있도록 합니다.</li>
          <li>회사는 필요한 경우 관련 법령을 위반하지 않는 범위 내에서 약관을 개정할 수 있으며, 개정 시 개정 내용 및 적용 일자를 명시하여 서비스 초기화면에 적용일자 7일 전부터 공지합니다.</li>
          <li>이용자는 개정된 약관에 동의하지 않을 권리가 있으며, 동의하지 않을 경우 서비스 이용이 제한될 수 있습니다.</li>
        </ol>
      </section>

      <section>
        <h2>제4조 (서비스의 제공 및 변경)</h2>
        <ol>
          <li>회사는 다음과 같은 서비스를 제공합니다:
            <ol type="a">
              <li>사용자가 업로드한 영상의 영어 음성을 한국어 자막으로 변환</li>
              <li>유튜브 링크를 통한 자막 변환 서비스</li>
              <li>변환된 자막의 다운로드 및 srt 파일 제공</li>
              <li>기타 회사가 정하는 서비스</li>
            </ol>
          </li>
          <li>회사는 기술적 또는 운영상의 이유로 제공하는 서비스를 변경할 수 있으며, 변경 사항이 있을 경우 이를 이용자에게 사전에 공지합니다.</li>
        </ol>
      </section>

      <section>
        <h2>제5조 (서비스의 중단)</h2>
        <ol>
          <li>회사는 시스템 점검, 교체, 장애 등의 이유로 서비스 제공이 일시적으로 중단될 수 있습니다. 이 경우, 회사는 사전에 이용자에게 통지합니다.</li>
          <li>회사는 고의 또는 중대한 과실이 없는 경우 서비스 중단으로 인한 손해에 대해 책임을 지지 않습니다.</li>
        </ol>
      </section>

      <section>
        <h2>제6조 (회원가입)</h2>
        <ol>
          <li>이용자는 회사가 제공하는 가입 양식에 필요한 정보를 기입하고 약관에 동의하여 회원가입을 신청할 수 있습니다.</li>
          <li>회사는 다음 각 호에 해당하는 경우 회원가입을 거부하거나 제한할 수 있습니다:
            <ol type="a">
              <li>타인의 정보를 도용하여 가입한 경우</li>
              <li>허위 정보를 기재한 경우</li>
              <li>기타 회사가 필요하다고 인정하는 경우</li>
            </ol>
          </li>
          <li>회원가입은 회사의 승낙이 이용자에게 도달한 시점에 성립됩니다.</li>
        </ol>
      </section>

      <section>
        <h2>제7조 (토큰 결제 및 이용)</h2>
        <ol>
          <li>이용자는 선불로 토큰을 구매하여 서비스 이용 시 결제 수단으로 사용할 수 있습니다. 현재 서비스는 6초당 1토큰을 차감하며, 이는 추후 변경될 수 있습니다.</li>
          <li>회사는 토큰 사용 정책을 사전 공지 후 변경할 수 있으며, 변경된 내용은 공지된 시점부터 적용됩니다.</li>
        </ol>
      </section>

      <section>
        <h2>제8조 (영상 저장 및 관리)</h2>
        <ol>
          <li>이용자가 업로드한 영상은 회사의 서버에 3일 동안 저장되며, 이후 자동으로 삭제됩니다.</li>
          <li>변환된 자막은 srt 파일 형태로 저장되며, 사용자는 언제든지 해당 파일을 다운로드할 수 있습니다.</li>
        </ol>
      </section>

      <section>
        <h2>제9조 (환불 정책)</h2>
        <p>이용자는 토큰을 환불받을 수 없습니다. 다만, 서비스 장애나 오류로 인해 서비스가 정상적으로 제공되지 않은 경우 회사는 해당 오류를 수정하거나 이에 상응하는 보상을 제공합니다.</p>
      </section>

      <section>
        <h2>제10조 (이용자의 의무)</h2>
        <ol>
          <li>이용자는 다음 각 호의 행위를 해서는 안 됩니다:
            <ol type="a">
              <li>타인의 영상 또는 권리를 침해하는 행위</li>
              <li>서비스 이용 과정에서 허위 정보를 제공하는 행위</li>
              <li>서비스를 악용하거나 부정 이용하는 행위</li>
            </ol>
          </li>
          <li>위반 시 회사는 사전 통지 없이 서비스 이용을 제한하거나 회원 자격을 박탈할 수 있습니다.</li>
        </ol>
      </section>

      <section>
        <h2>제11조 (개인정보보호)</h2>
        <ol>
          <li>회사는 이용자의 개인정보를 보호하기 위해 최선을 다하며, 관련 법령을 준수합니다.</li>
          <li>회사는 필요한 경우 관련 법령을 위반하지 않는 범위 내에서 약관을 개정할 수 있으며, 개정 시 개정 내용 및 적용 일자를 명시하여 서비스 초기화면에 적용일자 7일 전부터 공지합니다.</li>
          <li>이용자의 개인정보는 서비스 제공 및 관리 목적으로만 사용되며, 이용자의 동의 없이 제3자에게 제공되지 않습니다.</li>
        </ol>
      </section>

      <section>
        <h2>제12조 (면책 조항)</h2>
        <ol>
          <li>회사는 천재지변, 전쟁, 시스템 장애 등 불가항력적 사유로 인해 서비스를 제공할 수 없는 경우 책임을 지지 않습니다.</li>
          <li>회사는 이용자의 고의 또는 과실로 인한 피해에 대해 책임을 지지 않습니다.</li>
        </ol>
      </section>

      <section>
        <h2>제13조 (관할법원)</h2>
        <p>이 약관에 관한 분쟁은 대한민국 법률에 따라 해결하며, 관할법원은 회사의 본사 소재지를 관할하는 법원으로 합니다.</p>
      </section>

      <section>
        <h2>부칙</h2>
        <p>위 약관은 (주)에이젠다의 "WithG - 번역" 서비스에 적용되며, 이용자는 이를 충분히 숙지하고 동의한 후 서비스를 이용해야 합니다.</p>
      </section>
    </div>
  );
};

export default Terms;