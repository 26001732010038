import React from 'react';
import '../styles/NonLogin.css';

function NonLogin() {
  return (
    <div className="body-non-login">
      <h1>쉽고 빠르게 번역 자막을 확인하세요<br />로그인을 해주세요!</h1>
    </div>
  );
}

export default NonLogin;