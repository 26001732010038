import React, { useState, useEffect} from 'react';
import { apiCheckToken } from "../services/firebase/database";

import { applyColors } from '../styles/applyColors';
import '../styles/ConfirmUploadPopup.css';
applyColors();

function formatDuration(seconds) {
  seconds = Math.floor(seconds); // 소수점 이하를 내림
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  if (hours > 0) {
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  } else {
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }
}

function ConfirmUploadPopup({ user, fileName, videoDuration, useToken, onConfirm, onCancel, url = "none" }) {
  const [showError, setShowError] = useState(false);
  const [token, setToken] = useState(0);

  useEffect(() => {
    const fetchToken = async () => {
      if (user) {
        try {
          const userToken = await apiCheckToken(user.uid);
          setToken(userToken);
        } catch (error) {
          console.error("Failed to fetch token:", error);
        }
      }
    };

    fetchToken();
  }, [user]);

  const handleConfirm = () => {
    if (token < useToken) {
      setShowError(true);
    } else {
      onConfirm();
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>업로드 확인</h2>
        <p>다음 파일을 업로드하시겠습니까?</p>
        <p><strong>파일명:</strong> {fileName}</p>
        {url !== "none" && (
          <p>
            <a href={url} target="_blank" rel="noopener noreferrer">
              유튜브 링크 바로가기
            </a>
          </p>
        )}
        <p><strong>영상 시간:</strong> {formatDuration(videoDuration)}</p>
        <p>
          <strong>소요 토큰 수: </strong> 
          {useToken}
          {showError && <span className="error-message"> (토큰이 부족합니다)</span>}
        </p>
        <div className="popup-buttons">
          <button onClick={handleConfirm} className="confirm-button">확인</button>
          <button onClick={onCancel} className="cancel-button">취소</button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmUploadPopup;