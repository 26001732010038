import React, { useState, useRef } from 'react';
import { uploadFileToFirebase } from '../services/firebase/storage';
import { apiUploadVideo } from '../services/firebase/database';
import ConfirmUploadPopup from './ConfirmUploadPopup';
import axios from 'axios';

import { applyColors } from '../styles/applyColors';
import '../styles/FileUploader.css';
applyColors()

function FileUploader({user}) {
  const [dragActive, setDragActive] = useState(false);
  const [uploadStatus, setUploadStatus] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [videoDuration, setVideoDuration] = useState('');
  const [useToken, setUseToken] = useState(0);
  const fileInputRef = useRef(null);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const getVideoDuration = (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        const duration = video.duration;
        resolve(duration)
        setUseToken(Math.floor(duration / 6))
      };
      video.onerror = reject;
      video.src = URL.createObjectURL(file);
    });
  };

  const getOperationTranslator = (uid, title, usedtoken) => {
    axios.get(`https://0wbl0jewsu2y.share.zrok.io/operationtranslator?uid=${uid}&title=${title}&usedtoken=${usedtoken}`)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    axios.get(`https://shdobsuit0kt.share.zrok.io/send_update_video_email?uid=${uid}&title=${title}`)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  const handleFile = async (file) => {
    if (file.type !== 'video/mp4') {
      setUploadStatus('오류: MP4 파일만 업로드 가능합니다.');
      return;
    }

    try {
      const duration = await getVideoDuration(file);
      setFileToUpload(file);
      setVideoDuration(duration);

      setShowPopup(true);
    } catch (error) {
      setUploadStatus('오류: 비디오 길이를 가져올 수 없습니다.');
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0]);
    }
  };

  const handleFileInputChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files[0]);
    }
  };

  const handleContainerClick = (e) => {
    if (e.target === e.currentTarget) {
      fileInputRef.current.click();
    }
  };

  const handleConfirmUpload = () => {
    setShowPopup(false);
    uploadFile();
  };

  const handleCancelUpload = () => {
    setShowPopup(false);
    setFileToUpload(null);
    setVideoDuration('');
    setUploadStatus('');
    setUploadProgress(0);
  };

  const uploadFile = () => {
    if (!fileToUpload) return;

    uploadFileToFirebase(
      fileToUpload,
      (progress) => {
        setUploadProgress(progress);
        setUploadStatus(`업로드 중: ${progress.toFixed(2)}%`);
      },
      (error) => {
        setUploadStatus('업로드 실패: ' + error.message);
      },
      (fileName) => {
        const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, "");

        setUploadStatus('업로드 성공! 완료되면 메일로 알람이 가요!');
        apiUploadVideo(user.uid, fileNameWithoutExtension, "test", useToken)
        getOperationTranslator(user.uid, fileNameWithoutExtension, useToken)
      }
    );
  };

  return (
    <>
      <div 
        className={`file-uploader ${dragActive ? 'drag-active' : ''}`}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        onClick={handleContainerClick}
      >
        <input 
          type="file" 
          ref={fileInputRef}
          onChange={handleFileInputChange}
          accept="video/mp4"
          style={{display: 'none'}}
        />
        <p>MP4 파일을 여기에 드래그하거나 클릭하여 선택하세요</p>
        {uploadStatus && <p>{uploadStatus}</p>}
        {uploadProgress > 0 && uploadProgress < 100 && (
          <div className="progress-bar-container">
            <div 
              className="progress-bar"
              style={{width: `${uploadProgress}%`}}
            >
              {uploadProgress.toFixed(2)}%
            </div>
          </div>
        )}
      </div>
      {showPopup && (
        <ConfirmUploadPopup
          user={user}
          fileName={fileToUpload.name}
          videoDuration={videoDuration}
          useToken={useToken}
          onConfirm={handleConfirmUpload}
          onCancel={handleCancelUpload}
        />
      )}
    </>
  );
}

export default FileUploader;