import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { loadTossPayments } from "@tosspayments/tosspayments-sdk";

const clientKey = process.env.REACT_APP_TOSS_CLIENTKEY;
const customerKey = process.env.REACT_APP_TOSS_CUSTOMERKEY;

export function Checkout() {
  const [ready, setReady] = useState(false);
  const [widgets, setWidgets] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const selectedOption = location.state?.selectedOption;

  useEffect(() => {
    if (!selectedOption) {
      navigate('/addtoken');
      return;
    }

    const fetchPaymentWidgets = async () => {
      const tossPayments = await loadTossPayments(clientKey);
      const widgets = tossPayments.widgets({
        customerKey,
      });
      setWidgets(widgets);
    };

    fetchPaymentWidgets();
  }, [selectedOption, navigate]);

  useEffect(() => {
    const renderPaymentWidgets = async () => {
      if (widgets == null || !selectedOption) {
        return;
      }
      await widgets.setAmount({
        currency: "KRW",
        value: selectedOption.price,
      });

      await Promise.all([
        widgets.renderPaymentMethods({
          selector: "#payment-method",
          variantKey: "DEFAULT",
        }),
        widgets.renderAgreement({
          selector: "#agreement",
          variantKey: "AGREEMENT",
        }),
      ]);

      setReady(true);
    };

    renderPaymentWidgets();
  }, [widgets, selectedOption]);

  const handlePaymentRequest = async () => {
    if (selectedOption && widgets) {
      try {
        await widgets.requestPayment({
          orderId: `ORDER_${Date.now()}`,
          orderName: `${selectedOption.tokens} 토큰 구매`,
          successUrl: `${window.location.origin}/success`,
          failUrl: `${window.location.origin}/fail`,
          customerEmail: "customer123@gmail.com",
          customerName: "김토스",
          customerMobilePhone: "01012341234",
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  if (!selectedOption) {
    return null;
  }

  return (
    <div className="checkout-container">
      <h2>{selectedOption.tokens} 토큰 구매</h2>
      <p>가격: {selectedOption.price}원</p>
      <div id="payment-method" />
      <div id="agreement" />
      <button
        className="pay-button"
        disabled={!ready}
        onClick={handlePaymentRequest}
      >
        결제 진행하기
      </button>
    </div>
  );
}

export default Checkout;